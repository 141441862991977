'use client';

import React from 'react';
import Footer from '@/components/ui/Footer';
import Header from '@/components/ui/Header';
export default function HomeLayout({
  children
}) {
  return <main id="main" className="w-screen" data-sentry-component="HomeLayout" data-sentry-source-file="layout.jsx">
      <Header data-sentry-element="Header" data-sentry-source-file="layout.jsx" />

      {children}

      <Footer data-sentry-element="Footer" data-sentry-source-file="layout.jsx" />
    </main>;
}